import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  mobileVendor,
  mobileModel,
  osName,
  browserName,
  browserVersion,
} from "react-device-detect";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Icon,
  Input,
  TextArea,
} from "design-react-kit";
import { useTranslation } from "react-i18next";
import FileUpload from "./FileUpload";
import { sendTicket } from "../helpers/sendTicket";
import { useQueryParams } from "../helpers/utilities";
const TicketForm = ({ notify }) => {
  const { t } = useTranslation();

  const [screenshot, setScreenshot] = useState();
  const [screenshotFile, setScreenshotFile] = useState();
  const [attachments, setAattachments] = useState();
  const [stepSuccess, setStepSuccess] = useState(false);
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [showBtnScreenshot, setShowBtnScreenshot] = useState(true);
  const {
    support_endpoint,
    support_email,
    support_type,
    name,
    akFreshdesk,
    parentHref,
    username,
    tenant_id,
    roles,
    stack,
  } = useQueryParams();
  const getScreenshotFromBase64 = (b64) => {
    setScreenshot(b64);
    fetch(b64)
      .then((res) => res.blob())
      .then((a) => setScreenshotFile(a));
  };

  useEffect(() => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      setShowBtnScreenshot(false)
    }
  }, []);

  const getStack = () => {
    if (!stack) {
      return "n.d.";
    }
    switch (stack) {
      case "oc-boat-prodfast":
        return "fast";
      case "oc-boat-prod":
        return "slow";
      case "oc-boat-dev":
        return "dev";
      case "oc-boat-qa":
        return "qa";
      default:
        return stack;
    }
  };

  const getImage = () => {
    const capture = async () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const video = document.createElement("video");
      try {
        const captureStream = await navigator.mediaDevices.getDisplayMedia({
          // preferCurrentTab: true,
          selfBrowserSurface: "include",
        });
        video.srcObject = captureStream;
        await new Promise((resolve) => {
          video.onloadedmetadata = resolve;
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));
        video.play();

        const displayWidth = video.videoWidth;
        const displayHeight = video.videoHeight;

        canvas.width = displayWidth;
        canvas.height = displayHeight;
        context.drawImage(video, 0, 0, displayWidth, displayHeight);

        const frame = canvas.toDataURL("image/png");
        captureStream.getTracks().forEach((track) => track.stop());
        getScreenshotFromBase64(frame);
      } catch (err) {
        notify(
          "Si è verificato un problema durante l'acquisizione della schermata",
          "Per favore controlla le impostazioni del dispositivo. Se il problema persiste, carica manualmente il file dell'acquisizione schermata.",
          {
            dismissable: true,
            state: "warning",
          }
        );
      }
    };

    capture();
  };

  const getDeviceInfo = () => {
    if (mobileVendor && mobileVendor !== 'none') {
      return `Device: ${mobileVendor}${mobileModel ? ' '+mobileModel : ''}, `
    } else {
      return '';
    }
  }

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });
  const composeDescription = (data) => {
    data.description =
      data.description +
      `

----------------
Inviato da Widget Supporto

Username: ${username ? username : "n.d."}
Roles: ${roles ? roles : "n.d."}
Tenant Id: ${tenant_id ? tenant_id : "n.d."}
Stack: ${getStack()}

Page url: ${parentHref}
UserAgent: ${window.navigator.userAgent}
${getDeviceInfo()}os: ${osName}, browser: ${browserName} version: ${browserVersion}`;
    data.description = data.description.replace(/(?:\r\n|\r|\n)/g, "<br>");
    return data;
  };

  const onSubmit = (data) => {
    setSendBtnDisabled(true);
    data = composeDescription(data);
    sendTicket(support_endpoint, akFreshdesk, data, screenshotFile, attachments)
      .then(() => {
        setStepSuccess(true);
        setSendBtnDisabled(false);
      })
      .catch((err) => {
        setSendBtnDisabled(false);
        notify(t("Errore"), err, {
          dismissable: true,
          state: "error",
        });
      });
  };

  const clearScreenshot = () => {
    setScreenshot();
    setScreenshotFile();
  };

  if (stepSuccess) {
    return (
      <fieldset key={t("lang")} aria-label="Ticket Form" className="">
        <div className="form-title-wrapper p-2 pb-5 bg-dark bg-gradient text-white">
          <div className="form-title p-4 pb-5">
            <legend className="text-white text-wrap">
              <h6>Supporto tecnico {name}</h6>
            </legend>
          </div>
        </div>
        <div className="card-form-wrapper card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
          <Card
            className="card-bg card-big no-after no-afte rounded shadow mx-2"
            noWrapper
          >
            <div className="card-image-wrapper with-read-more">
              <CardBody className="p-4">
                <CardTitle className="fw-semibold pb-2">Contattaci</CardTitle>
                <div className="text-center py-5 my-5">
                  <Icon
                    className="bg-grey"
                    color="success"
                    icon="it-check-circle"
                    size="xl"
                  />
                  <h5 className="py-2">Segnalazione inviata</h5>
                </div>
              </CardBody>
            </div>
          </Card>
        </div>
      </fieldset>
    );
  }

  return (
    <fieldset key={t("lang")} aria-label="Ticket Form" className="">
      <div className="form-title-wrapper p-2 pb-5 bg-dark bg-gradient text-white">
        <div className="form-title p-4 pb-5">
          <legend className="text-white text-wrap">
            <h6>
              Supporto tecnico {name}
              {Boolean(support_type === "freshdesk") && (
                <>
                  <br /> In caso di problemi di utilizzo del sistema compila il
                  form. Riceverai risposta per email all’indirizzo indicato.
                  Ricordati di allegare uno screenshot che mostra il problema in
                  modo da aiutarci a identificarlo e a fornirti le informazioni
                  necessarie.
                </>
              )}
            </h6>
          </legend>
        </div>
      </div>
      <div className="card-form-wrapper card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
        <Card
          className="card-bg card-big no-after no-afte rounded shadow mx-2"
          noWrapper
        >
          <div className="card-image-wrapper with-read-more pb-5">
            <CardBody className="p-4">
              <CardTitle className="fw-semibold pb-2">Contattaci</CardTitle>
              {Boolean(support_type === "freshdesk") && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          label={t("Nome")}
                          id={"name"}
                          placeholder=""
                          invalid={
                            errors.name?.type === "required" ? true : false
                          }
                          innerRef={field.ref}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: t("Inserisci una mail valida"),
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          id={"email"}
                          type="email"
                          className={"cmp-input mb-0"}
                          label={t("Indirizzo email") + "*"}
                          placeholder={""}
                          valid={
                            errors.email?.type === "required" ||
                            errors.email?.type === "pattern"
                              ? false
                              : undefined
                          }
                          validationText={
                            (errors.email?.type === "required" ||
                              errors.email?.type === "pattern") &&
                            t("Inserisci una mail valida")
                          }
                          innerRef={field.ref}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Controller
                      name="subject"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          label={t("Oggetto")}
                          id={"subject"}
                          placeholder=""
                          invalid={
                            errors.subject?.type === "required" ? true : false
                          }
                          innerRef={field.ref}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="cmp-text-area">
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t("Inserisci una descrizione"),
                        },
                        maxLength: {
                          value: 20000,
                        },
                      }}
                      render={({ field }) => (
                        <TextArea
                          id={"description"}
                          className={"text-area"}
                          label={t("Descrizione") + "*"}
                          rows={5}
                          placeholder={""}
                          maxLength={20000}
                          valid={
                            errors.description?.message ? false : undefined
                          }
                          validationText={errors.description?.message}
                          innerRef={field.ref}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                  </div>
                  {Boolean(showBtnScreenshot) && (
                  Boolean(!screenshot) ? (
                    <div>
                      <Button
                        outline
                        color="secondary"
                        size="sm"
                        block
                        onClick={getImage}
                      >
                        <Icon
                          color="secondary"
                          icon="it-fullscreen"
                          size="sm"
                          title="Acquisisci una schermata"
                          aria-hidden
                        />
                        Acquisisci una schermata
                      </Button>
                    </div>
                  ) : (
                    <div className="rounded my-3 screenshot-wrapper overflow-hidden position-relative">
                      <Icon
                        className="screenshot-close position-absolute pointer"
                        color="white"
                        icon="it-close"
                        size="sm"
                        title="Elimina"
                        onClick={clearScreenshot}
                      />
                      <img
                        className={"screenshot-img"}
                        width={"100%"}
                        src={screenshot}
                        alt={"Screenshot"}
                      />
                    </div>
                  ))}
                  <FileUpload setAattachments={setAattachments} />
                  <Button
                    disabled={sendBtnDisabled}
                    type="submit"
                    color="dark"
                    className="mt-3"
                    block
                  >
                    {Boolean(sendBtnDisabled) && (
                      <span className="d-inline-flex align-items-center">
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {t("Invio...")}
                      </span>
                    )}
                    {Boolean(!sendBtnDisabled) && t("Invia")}
                  </Button>
                </form>
              )}
              {Boolean(support_type !== "freshdesk") && (
                <>
                  In caso di problemi di utilizzo del sistema invia una email al
                  seguente indirizzo
                  <p className={"mt-3"}>
                    <a
                      className={"cursor-pointer"}
                      href={`mailto:${support_email}`}
                    >
                      <Icon icon="it-mail" size={"sm"} color={"primary"}></Icon>
                      {support_email}
                    </a>
                  </p>
                  Ricordati di allegare uno screenshot che mostra il problema in
                  modo da aiutarci a identificarlo e a fornirti le informazioni
                  necessarie.
                </>
              )}
            </CardBody>
          </div>
        </Card>
      </div>
    </fieldset>
  );
};
export default TicketForm;
