function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
export function sendTicket(endpoint, freshdeskApiKey, data, screenshot, files) {
  const PATH = "/api/v2/tickets";
  const URL = endpoint + PATH;
  const formdata = new FormData();
  formdata.append("description", data.description);
  formdata.append("email", data.email);
  formdata.append("subject", data.subject);
  formdata.append("name", data.name);
  formdata.append("priority", "2");
  formdata.append("status", "2");
  if (screenshot) {
    formdata.append("attachments[]", screenshot, "Screenshot.png");
  }
  if (files?.length > 0) {
    files.map((file) => (
      formdata.append("attachments[]", file, file.name)
    ));
  }

  const requestOptions = {
    method: "POST",
    headers: { Authorization: "Basic " + btoa(freshdeskApiKey + ":x") },
    body: formdata,
  };
  return fetch(URL, requestOptions).then(handleResponse);
}
