import React from "react";
import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import "typeface-titillium-web";
import "typeface-roboto-mono";
import "typeface-lora";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { NotificationManager, notify } from "design-react-kit";
import TicketForm from "./components/TicketForm";
function App() {
  return (
    <div className="App">
      <Router>
        <TicketForm notify={notify} />
      </Router>
      <NotificationManager duration={15000}closeOnClick containerId="support-notify" />
    </div>
  );
}

export default App;
