import { Card, CardBody, Icon } from "design-react-kit";
import React, { useEffect, useState } from "react";
import { ErrorCode, useDropzone } from "react-dropzone";
const FileUpload = ({ setAattachments }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [numFile, setNumFile] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  function fileSizeSI(a, b, c, d, e) {
    return (
      ((b = Math),
      (c = b.log),
      (d = 1e3),
      (e = (c(a) / c(d)) | 0),
      a / b.pow(d, e)).toFixed(2) +
      " " +
      (e ? "kMGTPEZY"[--e] + "B" : "Bytes")
    );
  }
  function maxSizeValidator(file) {
    if (file.size + totalSize > 20000000) {
      return {
        code: "size-files",
        message: `Il totale dei file non può superare i 20Mb`,
      };
    }
    if (numFile >= 5) {
      return {
        code: "number-files",
        message: `Puoi caricare massimo 5 file`,
      };
    }

    return null;
  }
  const itErrorMessage = (error) => {
    switch (error.code) {
      case ErrorCode.FileInvalidType:
        return `Formato non supportato`;
      case ErrorCode.FileTooLarge:
        return `Il totale dei file non può superare i 20Mb`;
      case ErrorCode.TooManyFiles:
        return "Puoi caricare al massimo 5 file";
      default:
        return error.message;
    }
  };

  const itFileRejections = (fileRejections) => {
    return fileRejections.map((reject) => ({
      file: reject.file,
      errors: reject.errors.map((error) => ({
        code: error.code,
        message: itErrorMessage(error),
      })),
    }));
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      setRejectedFiles(itFileRejections(fileRejections));

      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    },
    maxFiles: 5,
    validator: maxSizeValidator,
    accept: {
      'application/pdf': ['.pdf'],
      'application/xml': ['.xml'],
      'text/csv': ['.csv'],
      'application/vnd.oasis.opendocument.presentation': ['.odp'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/msword': ['.doc'],
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/webp': ['.webp'],
      'image/apng': ['.apng']
    },
    noClick: true,
    noKeyboard: true
  });

  const removeFile = (x) => {
    uploadedFiles.splice(x, 1);
    setUploadedFiles(uploadedFiles);
    setNumFile(uploadedFiles.length);
  };
  useEffect(() => {
    setNumFile(uploadedFiles.length);
    const size = uploadedFiles
      .map((obj) => obj.size)
      .reduce((accumulator, current) => accumulator + current, 0);
    setTotalSize(size);
    setAattachments(uploadedFiles);
  }, [uploadedFiles, setAattachments]);

  return (
    <>
      <div
        className="my-3 border-2 border-secondary file-uploader-box rounded text-center p-3 text-secondary"
        {...getRootProps()}
        onClick={open}
      >
        <input
          // id="file-uploader"
          // aria-describedby="file-uploader-description"
          {...getInputProps()}
          
        />
        <Icon color="secondary" icon="it-upload" title="Icona Carica file" />
        <div>
          <label htmlFor="file-uploader" className="text-secondary">
            <b>Carica file (massimo 5)</b>
          </label>
        </div>
        <small id="file-uploader-description">
          Clicca per aggiungere oppure trascina i file
        </small>
        {/* <ul></ul> */}
      </div>
      {rejectedFiles.map(({ file, errors }, index) => (
        <Card
          key={file.name}
          teaser
          noWrapper
          className="rounded shadow mb-3 p-3"
        >
          <CardBody className="file-card d-inline-flex w-100" title={file.name}>
            <Icon color="danger" icon="it-error" title="Errore" />
            <div className="d-inline-block text-truncate">
              <div className="text-truncate">
                <del>{file.name}</del>
                <div className="file-size text-muted">
                  {fileSizeSI(file.size)}
                </div>
              </div>
              {errors.map((e) => (
                <div className="text-wrap text-danger">{e.message}</div>
              ))}
            </div>
          </CardBody>
        </Card>
      ))}
      {uploadedFiles.map((file, index) => (
        <Card
          key={file.name}
          teaser
          noWrapper
          className="rounded shadow mb-3 p-3"
        >
          <CardBody className="file-card d-inline-flex w-100" title={file.name}>
            <Icon color="primary" icon="it-file" title="Icona file" />
            <div className="d-inline-block text-truncate">
              <div className="file-name text-truncate">{file.name}</div>
              <div className="file-size text-muted">
                {fileSizeSI(file.size)}
              </div>
            </div>
            <Icon
              title="Rimuovi file"
              color="secondary"
              icon="it-delete"
              className="ms-auto pointer"
              onClick={() => removeFile(index)}
            />
          </CardBody>
        </Card>
      ))}
    </>
  );
};
export default FileUpload;
